import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContentInfo from "../components/ContentInfo"
import ReadMoreLink from "../components/ReadMoreLink"
import ArticleLink from "../components/ArticleLink"
import moment from "moment"
import "moment/locale/vi"
import ArticleAuthorInfo from "../components/ArticleAuthorInfo"

moment.locale("vi")

const IndexPage = ({ data }) => {


  return (
    <Layout>

      <div>
        {data.allStrapiArticle.edges.map(({ node: article }) => {
          return (
            <div style={{ marginBottom: 50 }} id={article.id} key={article.id}>
              <ArticleLink article={article}/>
              <ArticleAuthorInfo article={article}/>
              <ContentInfo article={article}/>
              <ReadMoreLink article={article}/>
              <hr/>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
    {
        allStrapiArticle(sort: {order: DESC, fields: updated_at}) {
            edges {
                node {
                    id
                    title
                    content
                    created_at
                    updated_at
                    author {
                        id
                        username
                        avatar {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`
